<template>

    <div class="px-3 md:px-0 pt-3">
       
        <p class=" text-xl leading-8 font-semibold tracking-tight text-gray-900 pb-4 flex-grow">
            Rapor Peserta Didik
        </p>

        <router-link :to="{name:'RaporDetail', params : {'singkatan_kategori' : $route.params.singkatan_kategori, 'id_kuis' : data.id_kuis }}" class="cursor-pointer flex items-center flex-row bg-white py-2 px-3 shadow rounded-lg my-2 transition-colors hover:border-gray-300"
            v-for="(data, index) in kuisList" :key="index">
            <div class="w-16">
                <div class="h-16 w-16 bg-blue-100 rounded-lg content-center flex items-center text-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mx-auto text-blue-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
                </div>
            </div>

            <div class="flex-grow ml-3 pt-1 pb-1">
                <h2 class="font-semibold tracking-tight text-gray-800 md:text-xl text-lg">{{ data.judul_kuis }}</h2>
                <span class="mr-5 text-gray-700 text-based">
                    {{ data.jumlah_percobaan }} Kali Percobaan
                </span>
            </div>
            <div class=" px-2 py-2 text-center text-white bg-gray-100 rounded-lg">
                <div class="text-base text-gray-800">{{ data.tertinggi.nilai_akhir }}</div>
                <div class="text-xs text-gray-600">Terakhir</div>
            </div>
            <router-link :to="{name:'RaporDetail', params : {'singkatan_kategori' : $route.params.singkatan_kategori, 'id_kuis' : data.id_kuis }}" class="ml-3 hidden md:block px-4 h-10 py-3 md:py-2 bg-yellow-200 text-yellow-700 rounded-lg font-semibold text-sm">
                Detail
            </router-link>
        </router-link>

        <div class="animate-pulse" v-if="isLoadingQuiz">
            <div class="flex bg-white py-2 px-3 border rounded-md my-2 transition-colors" v-for="i in 3" :key="i">
                <div class="h-16 w-16 bg-gray-100 rounded-lg content-center flex items-center text-center">
                </div>

                <div class="flex-grow ml-3 pt-1 pb-1">
                    <div class="h-6 bg-gray-100 rounded"></div>
                    <div class="h-5 w-32 mt-2 inline-block mr-2 bg-gray-100 rounded"></div>
                    <div class="h-5 w-40 mt-2 inline-block bg-gray-100 rounded"></div>
                </div>
            </div>
        </div>

        <br v-if="!isLoadingQuiz && kuisList.length > 0"/>

        <div class="text-center" v-if="!isLoadingQuiz && kuisList.length > 0 && !isMobile()">
            <router-link :to="{name: 'RaporPdf', params : {'singkatan_kategori' : $route.params.singkatan_kategori }}"
                class="group relative w-full transition-all py-3 px-4 border border-transparent text-sm font-lg rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                Lihat Rapor
            </router-link>
        </div>

        <div v-if="!isLoadingQuiz && kuisList.length == 0" class="text-center w-full mt-6" >
            <div class="w-2/3 px-5 mx-auto">
                <img src="@/assets/images/cloud-computing.svg" class="w-40 mx-auto" />
                <p class="mt-1 text-gray-800 font-semibold">Belum ada kuis yang anda kerjakan</p>
            </div>
        </div>

        <div class="h-12 md:h-0"></div>

        <div  class="py-4 px-3 bg-white w-full fixed md:bottom-0 bottom-12 right-0 left-0 border-t-2 border-gray-100" v-if="isMobile()">
            <div class="max-w-4xl mx-auto">
                <div @click="unduhRapor()"
                    class="relative w-full transition-all py-2 px-3 text-center border border-transparent text-sm font-lg rounded-md text-white bg-yellow-500 hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500">
                    Unduh Rapor
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { errorHandler, showErrorNotif } from '../../helpers/Tools';
    export default {
        name: 'Rapor',
        components : {
        },
        data (){
            return {
                isLoadingQuiz: true,
                page                  : 1,
                kuisList              : [],
                kategori              : null,
            }
        },
        mounted() {
			this.getKategoriDetail();
            this.scroll();
		},
        destroyed() {
            window.onscroll = () => {
            }
        },
		methods : {
            isMobile() {
                if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                    return true
                } else {
                    return false
                }
            },
            scroll() {
                window.onscroll = () => {
                    const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight
                    if (bottomOfWindow && this.kategori != null && !this.isEmpty) {
                        this.getKuisListResult()
                    }
                }
            },
            async getKategoriDetail() {
                this.isLoading = true;
                try {
                    let res = await this.$store.dispatch("getKategoriDetail", this.$route.params.singkatan_kategori)
                    let result = res.data
                    this.isLoading = false;
                    if(result.status == 'success') {
                        this.kategori = result.data;
                        setTimeout(() => {
                            this.getKuisListResult();
                        }, 100);
                    } 
                } catch (error) {
                    this.isLoading = false;
                    errorHandler(this, error);
                }
            },

            async getKuisListResult() {
                this.isLoadingQuiz = true;
                
                try {
                    let res = await this.$store.dispatch("getDaftarKuisDijawab", {
                        id_kategori: this.kategori.id_kategori,
                        page       : this.page
                    })
                    let result = res.data
                    this.isLoadingQuiz = false;

                    if(result.status == 'success') {
                        if(result.data.data.length == 0) {
                            this.isEmpty = true;
                        }

                        if(this.page == 1){
                            this.kuisList = result.data.data;
                        }else{
                            this.kuisList = [...this.kuisList, ...result.data.data]
                        }
                        this.page++;
                    } else {
                        showErrorNotif(this, result.message);
                    }
                } catch (error) {
                    this.isLoadingQuiz = false;
                    errorHandler(this, error);
                }
            },

            async unduhRapor() {
                let url = await this.$store.dispatch('getRaporUrl', {
                    singkatan_kategori : this.$route.params.singkatan_kategori
                });
                if(typeof window.flutter_inappwebview != 'undefined') {
                    window.flutter_inappwebview.callHandler('instancy.downloadfile', url);
                } else {
                    window.open(url, '_blank');
                }
            }
        },


    }
</script>
